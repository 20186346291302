<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户id，zb_user.id" prop="userId">
                <a-input v-model="queryParam.userId" placeholder="请输入用户id，zb_user.id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="最小年龄" prop="minAge">
                <a-input v-model="queryParam.minAge" placeholder="请输入最小年龄" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="最大年龄" prop="maxAge">
                  <a-input v-model="queryParam.maxAge" placeholder="请输入最大年龄" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="婚姻状态id，多个以英文逗分割,sys_dict_data.id" prop="marriageId">
                  <a-input v-model="queryParam.marriageId" placeholder="请输入婚姻状态id，多个以英文逗分割,sys_dict_data.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="最低身高" prop="minHeight">
                  <a-input v-model="queryParam.minHeight" placeholder="请输入最低身高" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="最高身高" prop="maxHeight">
                  <a-input v-model="queryParam.maxHeight" placeholder="请输入最高身高" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="最低体重" prop="minWeight">
                  <a-input v-model="queryParam.minWeight" placeholder="请输入最低体重" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="最高体重" prop="maxWeight">
                  <a-input v-model="queryParam.maxWeight" placeholder="请输入最高体重" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="学历id，多个以英文逗分割，sys_dict_data.id" prop="educationId">
                  <a-input v-model="queryParam.educationId" placeholder="请输入学历id，多个以英文逗分割，sys_dict_data.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="月收入id，多个以英文逗分割，sys_dict_data.id" prop="incomeId">
                  <a-input v-model="queryParam.incomeId" placeholder="请输入月收入id，多个以英文逗分割，sys_dict_data.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="月收入" prop="incomeValue">
                  <a-input v-model="queryParam.incomeValue" placeholder="请输入月收入" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="职业id，多个以英文逗分割，sys_dict_data.id" prop="careerId">
                  <a-input v-model="queryParam.careerId" placeholder="请输入职业id，多个以英文逗分割，sys_dict_data.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="现居地" prop="currentAddress">
                  <a-input v-model="queryParam.currentAddress" placeholder="请输入现居地" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否要孩子id，多个以英文逗分割，sys_dict_data.id" prop="exceptChildId">
                  <a-input v-model="queryParam.exceptChildId" placeholder="请输入是否要孩子id，多个以英文逗分割，sys_dict_data.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="吸烟id，多个以英文逗分割，sys_dict_data.id" prop="smokeIds">
                  <a-input v-model="queryParam.smokeIds" placeholder="请输入吸烟id，多个以英文逗分割，sys_dict_data.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="喝酒id，多个以英文逗分割，sys_dict_data.id" prop="drinkIds">
                  <a-input v-model="queryParam.drinkIds" placeholder="请输入喝酒id，多个以英文逗分割，sys_dict_data.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否有小孩,0-否 1-是" prop="isHaveSon">
                  <a-input v-model="queryParam.isHaveSon" placeholder="请输入是否有小孩,0-否 1-是" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:standard/info:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:standard/info:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:standard/info:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:standard/info:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :scroll="{x:true}"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:standard/info:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:standard/info:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['user:standard/info:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['user:standard/info:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageStandardInfo,listStandardInfo, delStandardInfo } from '@/api/user/standardInfo'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'StandardInfo',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        minAge: null,
        maxAge: null,
        marriageId: null,
        minHeight: null,
        maxHeight: null,
        minWeight: null,
        maxWeight: null,
        educationId: null,
        incomeId: null,
        incomeValue: null,
        careerId: null,
        currentAddress: null,
        exceptChildId: null,
        smokeIds: null,
        drinkIds: null,
        isHaveSon: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '用户id，zb_user.id',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最小年龄',
          dataIndex: 'minAge',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最大年龄',
          dataIndex: 'maxAge',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '婚姻状态id，多个以英文逗分割,sys_dict_data.id',
          dataIndex: 'marriageId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最低身高',
          dataIndex: 'minHeight',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最高身高',
          dataIndex: 'maxHeight',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最低体重',
          dataIndex: 'minWeight',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最高体重',
          dataIndex: 'maxWeight',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '学历id，多个以英文逗分割，sys_dict_data.id',
          dataIndex: 'educationId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '月收入id，多个以英文逗分割，sys_dict_data.id',
          dataIndex: 'incomeId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '月收入',
          dataIndex: 'incomeValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '职业id，多个以英文逗分割，sys_dict_data.id',
          dataIndex: 'careerId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '现居地',
          dataIndex: 'currentAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否要孩子id，多个以英文逗分割，sys_dict_data.id',
          dataIndex: 'exceptChildId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '吸烟id，多个以英文逗分割，sys_dict_data.id',
          dataIndex: 'smokeIds',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '喝酒id，多个以英文逗分割，sys_dict_data.id',
          dataIndex: 'drinkIds',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否有小孩,0-否 1-是',
          dataIndex: 'isHaveSon',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户择偶信息列表 */
    getList () {
      this.loading = true
     pageStandardInfo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        minAge: undefined,
        maxAge: undefined,
        marriageId: undefined,
        minHeight: undefined,
        maxHeight: undefined,
        minWeight: undefined,
        maxWeight: undefined,
        educationId: undefined,
        incomeId: undefined,
        incomeValue: undefined,
        careerId: undefined,
        currentAddress: undefined,
        exceptChildId: undefined,
        smokeIds: undefined,
        drinkIds: undefined,
        isHaveSon: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delStandardInfo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-standard-info/export', {
            ...that.queryParam
          }, `用户择偶信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
